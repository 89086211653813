import storage from './storage.js'
import {
  PLATE_ID,
  PLATE_LIST,
  REPORTLIST,
  ANSWER_LIST,
  HEXAGRAM_LIST,
  NAMEING_LIST
} from './contants.js'

// 生成获取方法
const getList = (key) => {
  return function () {
    return storage.get(key) || []
  }
}

// 生成添加方法
const addList = (key, targetKey) => {
  return function (value) {
    const list = storage.get(key) || []

    const index = list.findIndex((item) => item[targetKey] === value[targetKey])
    if (index > -1) list[index] = value
    else list.push(value)
    // storage.get(key)
    storage.set(key, list)
  }
}

/**
 * 生日报告添加获取
 *
 * */
export const addReport = addList(REPORTLIST, 's')

export const getReport = getList(REPORTLIST)

/**
 * 排盘历史添加获取
 *
 * */
const addPlate = addList(PLATE_LIST, 'id')

// 添加id
export const addPlateId = (plate) => {
  const id = Date.now().toString(16)
  addPlate({ id, ...plate })
  storage.set(PLATE_ID, id)
}

export const getPlateList = getList(PLATE_LIST)

// 通过id获取排盘信息
export const getPlateById = (id) => {
  const plateList = getPlateList()
  return plateList.find((item) => item.id === id)
}

/**
 * 答案之书
 *
 */
export const getAnswerList = getList(ANSWER_LIST)

export const addAnswer = addList(ANSWER_LIST, 'orderNo')

export const getAnswerByOrderNo = (orderNo) => {
  const list = getAnswerList()
  return list.find((item) => item.orderNo === orderNo)
}

/**
 * 问卦咨询
 */
export const getHexagram = getList(HEXAGRAM_LIST)

export const addHexagram = addList(HEXAGRAM_LIST, 's')

/**
 * 问卦咨询
 */
export const getNaming = getList(NAMEING_LIST)

export const addNaming = addList(NAMEING_LIST, 's')
