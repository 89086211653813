<template>
  <animation>
    <div class="pc-menu-wrapper" :style="style" v-show="show">
      <div class="menu-content" @click="jump">
        <!-- 关于 -->
        <template v-if="currentPath !== pcRouter.home">
          <div class="big-title pointer" :data-path="pcRouter.home">
            {{ $t('about') }}
          </div>
          <ul class="route-list">
            <li
              class="route pointer"
              v-for="route in aboutRouter"
              :key="route.path"
              :data-path="route.path"
            >
              {{ $t(route.name) }}
            </li>
          </ul>
        </template>
        <!-- 线上测试 -->
        <template v-if="currentPath !== pcRouter.predict">
          <div class="big-title pointer" :data-path="pcRouter.predict">
            {{ $t('pc.predict') }}
          </div>
          <ul class="route-list">
            <li
              class="route"
              v-for="route in predictRouter"
              :key="route.path"
              :data-path="route.path"
            >
              {{ route.name }}
            </li>
            <li class="route" @click.stop="openHistory">
              {{ $t('menu.history') }}
            </li>
          </ul>
        </template>
        <!-- 协议 -->
        <div class="big-title mt-20 pointer" @click.stop="jumpUser">
          {{ $t('menu.userArgeement') }}
        </div>
        <div class="big-title mt-20 pointer" @click.stop="jumpPrivacy">
          {{ $t('menu.privacyArgeement') }}
        </div>
      </div>
      <History
        :style="{
          top: pxtoRem('178px'),
          height: `calc(100vh - ${pxtoRem(178)})`,
          left: '50%',
          transform: 'translateX(-50%)'
        }"
        ref="historyRef"
      />
    </div>
  </animation>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { path } from '@/router/constant'
import { pcRouter } from '@/router/pcRouter'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Animation from '@/components/Animation.vue'
import History from '@/components/Header/components/History.vue'
import { agreement, isLang } from '@/utils/utils'
import pxtoRem from '@/utils/pxToRem'
import { LANG } from '@/utils/contants'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  style: {
    type: Object,
    default() {
      return {}
    }
  }
})

const emits = defineEmits(['update:show'])

const historyRef = ref()
const i18n = useI18n()
const route = useRoute()
const router = useRouter()

const currentPath = ref(route.path)

const aboutRouter = computed(() => {
  const routes = [
    {
      path: `${pcRouter.home}#download`,
      name: 'pc.download'
    },
    {
      path: `${pcRouter.home}#introduce`,
      name: 'pc.introduce'
    }
  ]

  return routes
})

const predictRouter = computed(() => {
  const route = [
    {
      path: path.personality.home,
      name: i18n.t('menu.person')
    },
    {
      path: path.destiny.home,
      name: i18n.t('menu.destiny')
    },
    {
      path: path.love.home,
      name: i18n.t('menu.love')
    },
    {
      path: path.relation.home,
      name: i18n.t('menu.relation')
    },
    {
      path: path.profession.home,
      name: i18n.t('menu.profession')
    },
    { path: path.birth.home, name: i18n.t('menu.entire') },

    {
      path: path.match.home,
      name: i18n.t('pc.love')
    },
    {
      path: path.siblings.home,
      name: i18n.t('siblings.title')
    },
    {
      path: path.annual.home,
      name: i18n.t('menu.annual_custom')
    },

    {
      path: path.enterprise.home,
      name: i18n.t('menu.enterprise')
    },
    {
      path: path.career.home,
      name: i18n.t('career.title')
    },
    {
      path: path.academic.home,
      name: i18n.t('academic.title')
    },
    {
      path: path.plate.home,
      name: i18n.t('menu.plate')
    },
    {
      path: path.answer.home,
      name: i18n.t('menu.answer')
    },

    {
      path: path.hexagram.home,
      name: i18n.t('front.hexagram')
    },

    {
      path: path.swiper,
      name: i18n.t('front.annual_bold')
    }
  ]

  if (!isLang(LANG.KR)) {
    route.splice(-1, 0, {
      path: path.naming.home,
      name: i18n.t('naming.title')
    })
  }

  return route.filter((itm) => itm.path !== currentPath.value)
})

const openHistory = (opt) => {
  historyRef.value.show(opt)
}

const jump = (e) => {
  console.log(e)

  const path = e.target.dataset?.path
  if (!path) return
  location.href = `${location.origin}${path}`
  emits('update:show', false)
}

const jumpUser = () => {
  location.href = agreement().user
}

const jumpPrivacy = () => {
  location.href = agreement().secret
}

defineExpose({ openHistory })

watch(
  () => props.show,
  (val) => {
    if (val) {
      currentPath.value = route.path
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
      openHistory({ value: false })
    }
  }
)
</script>

<style lang="less" scoped>
.pc-menu-wrapper {
  overflow-y: auto;
  box-shadow: inset 0px 2px 2px 0px rgba(144, 144, 144, 0.4);
  position: fixed;
  z-index: 3;
  top: 59px;
  height: calc(100vh - 59px);
  width: 100vw;
  background-color: #fff;
  .mt-20 {
    margin-top: 20px;
  }
  .pointer {
    cursor: pointer;
    &:active {
      background-color: #eee;
    }
  }
  .menu-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: 0 auto;
    box-sizing: border-box;
    padding: 17px 28px 100px;
    max-width: 375px;
    min-width: 350px;
    width: 100%;
    .big-title {
      font-weight: bold;
      font-size: 18px;
      color: #222222;
      line-height: 25px;
    }
    .route-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-top: 13px;
      padding-left: 55px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 23px;
      .route {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
