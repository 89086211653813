<template>
  <div class="report-list">
    <div class="title">
      <div class="type">{{ $t('naming.lastName') }}</div>
      <div class="name">{{ $t('birth') }}</div>
      <div class="operate">{{ $t('menu.operate') }}</div>
    </div>
    <ul class="list" @touchmove.stop="">
      <li class="report" v-for="report in reportList">
        <div class="type">
          <span>{{ report.name }}</span>
        </div>
        <div class="name">
          <span>{{ report.birth }}</span>
        </div>
        <div class="operate" @click="checkReport(report)">
          {{ $t('menu.detail') }} >>
        </div>
      </li>
      <li v-if="!reportList.length" class="no-item">
        <span>{{ $t('menu.no_history') }}</span>
      </li>
    </ul>
    <div class="order-button" @click="openOrder">{{ $t('menu.no_order') }}</div>
  </div>
</template>
<script setup>
import { getReport } from '@/utils/menu'
import storage from '@/utils/storage'
import { path } from '@/router/constant'
import { ref, computed, inject, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ORDER_MENU, NAMEING_LIST } from '@/utils/contants'

const { mitt } = getCurrentInstance().proxy
const closeMenu = inject('closeMenu')
const router = useRouter()
const route = useRoute()
const i18n = useI18n()

const reportList = storage.get(NAMEING_LIST) || []

const checkReport = (report) => {
  storage.set('s', report.s)

  if (route.path.includes(path.naming.detail)) {
    router.push({
      path: path.naming.detail
    })
    setTimeout(() => {
      mitt.emit('updateS')
    }, 0)
  } else
    router.push({
      path: path.naming.detail
    })

  closeMenu()
}

const openOrder = () => {
  mitt.emit(ORDER_MENU)
}

onMounted(() => {
  const list = document.querySelector('.report-list .list')
  list.addEventListener('touchmove', (e) => {
    e.stopImmediatePropagation()
  })
})
</script>
<style lang="less" scoped>
.report-list {
  height: 100%;
  padding-top: 66px;
  font-size: 52px;

  .title {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  .list {
    max-height: 1420px;
    height: 100%;
    font-size: 52px;
    overflow-y: auto;

    .report {
      display: flex;
      justify-content: space-between;
      padding: 45px 0;

      .name {
        & > span {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .operate {
        color: #5193ff;
      }
    }

    .no-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .type,
  .name,
  .operate {
    width: 33%;
    text-align: center;
  }

  .order-button {
    margin: 0 auto;
    margin-top: 50px;
    width: 932px;
    height: 181px;
    line-height: 181px;
    font-size: 72px;
    color: #fff;
    background: #5193ff;
    border-radius: 119px;
    text-align: center;
  }
}
</style>
